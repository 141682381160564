<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="classe"
              rules="required"
            >
              <b-form-group label="Classe" label-for="v-classe">
                <b-form-input
                  id="v-classe"
                  v-model="form.classe"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Classe"
                  data-cy="classe"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!addressTypePlace.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') &&
                      !addressTypePlace.id)
                  "
                />
                <small data-cy="v-classe" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Natureza"
              rules="required"
            >
              <b-form-group label="Natureza" label-for="v-codNatureza">
                <b-form-input
                  id="v-codNatureza"
                  v-model="form.codNatureza"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Natureza"
                  data-cy="natureza"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-natureza" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Novo campo tipo (receita/despesa) -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Tipo"
              rules="required"
            >
              <b-form-group label="Tipo" label-for="v-tipo">
                <b-form-select
                  id="v-tipo"
                  v-model="form.tipo"
                  :state="errors.length > 0 ? false : null"
                  :options="tipoOptions"
                  data-cy="tipo"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-tipo" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_FINAN_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_FINAN_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('accountPlanSettingsModule', {
      accountPlan: (state) => state.accountPlan
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Plano de Contas',
      required,
      tipoOptions: [
        { value: 1, text: 'Receita' },
        { value: 0, text: 'Despesa' }
      ],
      form: {
        agencia: '',
        numero: '',
        banco: {
          id: null
        },
        tipo: null
      }
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.accountPlan))

    // Converter o tipo de string para valor numérico se necessário
    if (typeof this.form.tipo === 'string') {
      const normalizedTipo = this.normalizeText(this.form.tipo)

      // Encontrar a opção correspondente usando texto normalizado
      const tipoOption = this.tipoOptions.find(
        (option) => this.normalizeText(option.text) === normalizedTipo
      )

      if (tipoOption) {
        this.form.tipo = tipoOption.value
      } else {
        this.form.tipo = null
      }
    }
  },
  methods: {
    // Método para normalizar texto (converter para minúsculas e remover acentos)
    normalizeText(text) {
      if (!text) return ''

      return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    },

    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('accountPlanSettingsModule/resetAccountPlan')
    },

    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // Criar uma cópia do formulário para enviar ao backend
          const formToSubmit = { ...this.form }

          // Converter o valor numérico para texto antes de enviar ao backend
          if (formToSubmit.tipo !== null && formToSubmit.tipo !== undefined) {
            const tipoOption = this.tipoOptions.find(
              (option) => option.value === formToSubmit.tipo
            )
            if (tipoOption) {
              formToSubmit.tipoText = tipoOption.text
            }
          }

          if (this.form.id) {
            this.$store
              .dispatch(
                'accountPlanSettingsModule/editAccountPlan',
                formToSubmit
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch(
                'accountPlanSettingsModule/insertNewAccountPlan',
                formToSubmit
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },

    cleanObjeto() {
      this.form = {
        classe: '',
        codNatureza: '',
        tipo: null
      }
    }
  }
}
</script>
